import { FC } from "react";
import Link from "next/link";
import { cn } from "@/lib/utils";
import { navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";

interface NavMenuButtonProps {
  href?: string;
  text: string;
  onClick?: () => void;
  className?: string;
}

const NavMenuButton: FC<NavMenuButtonProps> = ({ href, text, onClick, className }) => {
  const combinedClassName = cn(navigationMenuTriggerStyle(), "text-slate-600 dark:text-slate-200", className);

  return href ? (
    <Link href={href} className={combinedClassName}>
      {text}
    </Link>
  ) : (
    <button onClick={onClick} className={combinedClassName}>
      {text}
    </button>
  );
};

export default NavMenuButton;
