"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";

export default function NewsletterSignup() {
  const [email, setEmail] = useState("");

  return (
    <div className="space-y-4 md:w-1/2 text-center">
      <h3 className="text-lg font-semibold">Newsletter</h3>
      <p>Sign up and be the first to hear our news and special offers!</p>
      <div className="flex flex-col sm:flex-row items-center gap-2">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full sm:w-auto flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
        />
        <Button className="primary">
          Receive Info
        </Button>
      </div>
    </div>
  );
}
