"use client"

import React, { createContext, useContext } from "react";
import { Settings } from "@/lib/types";

const SettingsContext = createContext<Settings | null>(null);

export function SettingsProvider({ settings, children }: { settings: Settings; children: React.ReactNode }) {
  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
}
