"use client";

import Link from "next/link";
import MainMenu from "@/components/ui/main-menu";
import Container from "./Container";
import { useMediaQuery } from "@/hooks/use-media.query";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { MenuIcon } from "lucide-react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import NavMenuButton from "./NavMenuButton";
import { UserAccountNav } from "./UserAccountNav";
import Logo from "@/app/(website)/components/Logo";
import { ModeToggle } from "./ModeToggle";
interface NavbarProps {
  user: any;
  isAuthenticated: boolean;
}

const Navbar = ({ user, isAuthenticated }: NavbarProps) => {
  const isDesktop = useMediaQuery("(min-width:768px)");

  const destinations = [
    {
      title: "Popular Cities",
      href: "/destinations/popular-cities",
      submenu: [
        { title: "Tokyo", href: "/destinations/popular-cities/tokyo" },
        { title: "Kyoto", href: "/destinations/popular-cities/kyoto" },
        { title: "Osaka", href: "/destinations/popular-cities/osaka" },
      ],
    },
    {
      title: "Nature & Outdoors",
      href: "/destinations/nature-outdoors",
      submenu: [
        { title: "Mount Fuji", href: "/destinations/nature-outdoors/mount-fuji" },
        { title: "Hokkaido", href: "/destinations/nature-outdoors/hokkaido" },
        { title: "Okinawa", href: "/destinations/nature-outdoors/okinawa" },
      ],
    },
    {
      title: "Cultural Sites",
      href: "/destinations/cultural-sites",
      submenu: [
        { title: "Temples", href: "/destinations/cultural-sites/temples" },
        { title: "Shrines", href: "/destinations/cultural-sites/shrines" },
        { title: "Historic Sites", href: "/destinations/cultural-sites/historic-sites" },
      ],
    },
  ];

  const mobileMenu = (
    <Drawer direction="left">
      <DrawerTrigger>
        <MenuIcon className="text-slate-400" />
      </DrawerTrigger>
      <DrawerContent>
        <div className="mt-10">
          <Accordion type="single" defaultValue="getting_started" collapsible>
            <AccordionItem value="getting_started">
              <AccordionTrigger>Getting Started</AccordionTrigger>
              <AccordionContent>
                <div>
                  <Link href="/about-us" passHref>
                    Introduction
                  </Link>
                </div>
                <div>
                  <Link href="/about-us" passHref>
                    About us
                  </Link>
                </div>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="destinations">
              <AccordionTrigger>Destinations</AccordionTrigger>
              <AccordionContent>
                {destinations.map((item) => (
                  <div key={item.title}>
                    <Link href={item.href} passHref>
                      {item.title}
                    </Link>
                    {item.submenu && (
                      <div className="ml-4 mt-2">
                        {item.submenu.map((subItem) => (
                          <div key={subItem.title}>
                            <Link href={subItem.href} passHref>
                              {subItem.title}
                            </Link>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </DrawerContent>
    </Drawer>
  );

  return (
    <Container>
      <nav className="sticky top-0 z-50 flex items-center py-5 px-6 w-full justify-between">
        {/* Left Menu for Desktop / Mobile Menu Icon */}
        <div className="flex items-center space-x-4">
          {isDesktop ? (
            <MainMenu destinations={destinations} />
          ) : (
            mobileMenu
          )}
        </div>

        {/* Centered Logo */}
        <div className="absolute left-1/2 transform -translate-x-1/2">
          <Link href="/" passHref aria-label="Home">
            <Logo className="h-8 w-auto" />
          </Link>
        </div>

        {/* User Account or Sign-In Button */}
        <div className="flex items-center space-x-4 ml-auto">
          {isAuthenticated ? (
            <UserAccountNav user={user} />
          ) : (
            <NavMenuButton href="/pages/sign-in" text="Sign in" />
          )}
        </div>
      </nav>
    </Container>
  );
};

export default Navbar;
