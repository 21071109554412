import Image from 'next/image';

interface LogoProps {
  className?: string;
}

const Logo = ({ className = "" }: LogoProps): JSX.Element => (
  <div className={`flex items-center ${className}`}>
    <Image
      src="https://storage.googleapis.com/photo-portfolio/tara-japan/tara-japan-logo-v3.png"
      alt="Logo"
      width={100}
      height={100}
    />
  </div>
);

export default Logo;
