// Footer.tsx
"use client";

import Link from "next/link";
import NewsletterSignup from "../components/NewsLetterSignup";
import SocialLinks from "../components/SocialLinks";
import ContactInfo from "../components/ContactInfo";
//import { ModeToggle } from "../components/ModeToggle";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 py-10">
      {/* <ModeToggle /> */}
      <div className="container mx-auto max-w-7xl space-y-2">
        {/* Top Legal Links */}

        {/* Newsletter, Social Media, and Contact Info */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-10 mx-auto mb-6">
          <NewsletterSignup />
          <SocialLinks />
          <ContactInfo />
        </div>

        {/* Footer Links Section for Tourists and Residents */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-8 text-sm py-10">
          {/* Explore Japan for Tourists */}
          <div>
            <h3 className="font-semibold mb-2">Explore Tara Japan</h3>
            <ul className="space-y-1">
              <li><Link href="/tours">Visiting Japan</Link></li>
              <li><Link href="/tours/group">Living in Japan</Link></li>
              <li><Link href="/tours/self-guided">Self-Guided Tours</Link></li>
              <li><Link href="/tours/personalized">Personalized Tours</Link></li>
            </ul>
          </div>

          {/* Plan Your Trip */}
          <div>
            <h3 className="font-semibold mb-2">Plan Your Trip</h3>
            <ul className="space-y-1">
              <li><Link href="/transport/japan-rail-pass">Japan Rail Pass</Link></li>
              <li><Link href="/transport/tickets">Train Reservations</Link></li>
              <li><Link href="/accommodation/house-rentals">Hotel and Accomodation Guide</Link></li>
            </ul>
          </div>

          {/* Living in Japan for Residents */}
          <div>
            <h3 className="font-semibold mb-2">Living in Japan</h3>
            <ul className="space-y-1">
              <li><Link href="/living/jobs">Job Opportunities</Link></li>
              <li><Link href="/living/healthcare">Healthcare Services</Link></li>
              <li><Link href="/living/legal">Legal Assistance</Link></li>
              <li><Link href="/living/banking">Banking & Finance</Link></li>
              <li><Link href="/living/housing">Housing Support</Link></li>
            </ul>
          </div>

          {/* Community Resources */}
          <div>
            <h3 className="font-semibold mb-2">Community</h3>
            <ul className="space-y-1">
              <li><Link href="/community/events">Filipino Events</Link></li>
              <li><Link href="/community/embassy">Philippine Embassy</Link></li>
              <li><Link href="/community/organizations">Community Organizations</Link></li>
              <li><Link href="/community/volunteering">Volunteering</Link></li>
            </ul>
          </div>

          {/* About Tara Japan */}
          <div>
            <h3 className="font-semibold mb-2">About Tara Japan</h3>
            <ul className="space-y-1">
              <li><Link href="/pages/about">Who We Are</Link></li>
              <li><Link href="/pages/contact">Contact Us</Link></li>
              <li><Link href="/newsletter">Newsletter Signup</Link></li>
              <li><Link href="/affiliates">Affiliate Program</Link></li>
              <li><Link href="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-600 dark:text-gray-400">
          <Link href="/pages/contact">Contact</Link>
          {/* <Link href="/legal-terms">Legal Terms</Link> */}
          <Link href="/pages/terms-and-conditions">Terms and Conditions</Link>
          <Link href="/pages/privacy">Privacy Policy</Link>
          {/* <Link href="/insurance">Insurance</Link> */}
          <Link href="/pages/cookies">Cookies</Link>
          <Link href="/pages/terms-of-service">Terms of Service</Link>
          {/* <Link href="/secured-payment">Secured Payment</Link> */}
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-300 dark:border-gray-600 pt-4 text-center text-xs text-gray-600 dark:text-gray-400">
          <p>© {currentYear} Tara Japan. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
