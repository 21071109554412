import { FC } from "react";
import { User } from "next-auth";
import { Avatar, AvatarFallback } from "@radix-ui/react-avatar";
import Image from "next/image";
import { Icons } from "./Icons";
import { cn } from "@/lib/utils";

interface UserAvatarProps {
  user: Pick<User, "name" | "image">;
  className?: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ user, className }) => {
  return (
    <Avatar className={cn("relative h-10 w-10", className)}>
      {user.image ? (
        <div className="relative aspect-square h-full w-full">
          <Image
            fill
            src={user.image}
            alt={`${user.name || "User"}'s profile picture`}
            referrerPolicy="no-referrer"
            className="object-cover rounded-full"
          />
        </div>
      ) : (
        <AvatarFallback className="flex items-center justify-center bg-gray-200 rounded-full">
          <span className="sr-only">{user?.name || "User"}</span>
          <Icons.user className="h-4 w-4 text-gray-500" />
        </AvatarFallback>
      )}
    </Avatar>
  );
};

export default UserAvatar;
