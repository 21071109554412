// ContactInfo.tsx
"use client";

import Link from "next/link";
//import { Icons } from "./Icons";

export default function ContactInfo() {
  return (
    <div className="text-center text-auto">
      <h3 className="text-lg font-semibold">Contact us</h3>
      <address className="not-italic text-sm text-gray-600 dark:text-gray-400">
        Tokyo, Japan <br />
        {/* Osaka, Japan <br />
        Manila, Philippines <br /> */}
      </address>
      {/* <div className="flex items-center justify-center md:justify-start space-x-2 text-gray-600 dark:text-gray-400">
        <Icons.phone />
        <span>+1 800 000 0000</span>
      </div> */}
      <Link href="/pages/contact" className="block text-blue-500 hover:underline">
        Contact us
      </Link>
    </div>
  );
}
