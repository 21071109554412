"use client";

import Link from "next/link";
import { Icons } from "./Icons";

export default function SocialLinks() {
  return (
    <div className="space-y-4 text-center md:text-left">
      <h3 className="text-lg font-semibold">Social Networks</h3>
      <p className="text-gray-500">Follow us on social networks</p>
      <div className="flex gap-4 justify-center md:justify-start">
        <Link href="https://facebook.com" aria-label="Facebook" className="social-icon">
          <Icons.facebook className="h-6 w-6" />
        </Link>
        <Link href="https://instagram.com" aria-label="Instagram" className="social-icon">
          <Icons.instagram className="h-6 w-6" />
        </Link>
        <Link href="https://twitter.com" aria-label="Twitter" className="social-icon">
          <Icons.twitter className="h-6 w-6" />
        </Link>
        <Link href="https://threads.com" aria-label="Threads" className="social-icon">
          <Icons.threads className="h-6 w-6" />
        </Link>
        <Link href="https://youtube.com" aria-label="YouTube" className="social-icon">
          <Icons.youtube className="h-6 w-6" />
        </Link>
      </div>
    </div>
  );
}
