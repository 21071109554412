"use client";

import React from "react";
import Link from "next/link";
import { signOut } from "next-auth/react";
import UserAvatar from "./UserAvatar";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
} from "@/components/ui/navigation-menu";

interface UserAccountNavProps {
  user?: {
    name?: string;
    image?: string;
    email?: string;
  } | null;
}

export function UserAccountNav({ user }: UserAccountNavProps) {
  if (!user) {
    return null;
  }
  return (
    <NavigationMenu>
      <NavigationMenuList className="flex space-x-2 ">
        <NavigationMenuItem>
          <>
            <NavigationMenuTrigger className="bg-transparent hover:bg-transparent">
              <UserAvatar
                user={{
                  name: user.name || "Guest",
                  image: user.image || null,
                }}
                className="h-8 w-8 cursor-pointer bg-transparent"
              />
            </NavigationMenuTrigger>

            {/* Dropdown Content */}
            <NavigationMenuContent className="p-4 border rounded-lg z-50 w-[250px] left-0">
              {/* User Information */}
              <div className="flex items-center gap-2 mb-4">
                <UserAvatar
                  user={{ name: user.name, image: user.image }}
                  className="h-10 w-10"
                />
                <div>
                  {user.name && <p className="font-medium">{user.name}</p>}
                  {user.email && (
                    <p className="text-sm text-gray-500">
                      {user.email}
                    </p>
                  )}
                </div>
              </div>

              {/* Menu Links */}
              <ul className="space-y-1">
                <li>
                  <Link href="/profile" passHref>
                    <NavigationMenuLink asChild className="block w-full text-left px-2 py-1 rounded hover:bg-gray-100">
                      My Profile
                    </NavigationMenuLink>
                  </Link>
                </li>
                <li>
                  <Link href="/settings" passHref>
                    <NavigationMenuLink asChild className="block w-full text-left px-2 py-1 rounded hover:bg-gray-100">
                      Settings
                    </NavigationMenuLink>
                  </Link>
                </li>
                <li>
                  <button
                    onClick={() => signOut({ callbackUrl: `${window.location.origin}/sign-in` })}
                    className="block w-full text-left px-2 py-1 rounded hover:bg-gray-100 cursor-pointer"
                  >
                    Sign out
                  </button>
                </li>
              </ul>
            </NavigationMenuContent>
          </>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

export default UserAccountNav;
