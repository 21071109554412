import React from "react";
import Link from "next/link";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
  navigationMenuTriggerStyle
} from "@/components/ui/navigation-menu";

interface MainMenuProps {
  destinations: Array<{ title: string; href: string; submenu?: Array<{ title: string; href: string }> }>;
}

const MainMenu = ({ destinations }: MainMenuProps) => {
  const renderSubMenu = (submenu) => (
    <ul className="pl-4 mt-2 space-y-2">
      {submenu.map((item) => (
        <li key={item.title}>
          <Link href={item.href} passHref className="block text-sm leading-tight text-gray-600 hover:underline">
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <NavigationMenu>
      <NavigationMenuList className="flex space-x-2">
        <NavigationMenuItem>
          <NavigationMenuTrigger>Getting started</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              {/* <li className="row-span-3">
                <NavigationMenuLink
                  className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                  href="/"
                >
                  <div className="mb-2 mt-4 text-lg font-medium">
                    shadcn/ui
                  </div>
                  <p className="text-sm leading-tight text-muted-foreground">
                    Beautifully designed components that you can copy and paste into your apps. Accessible. Customizable. Open Source.
                  </p>
                </NavigationMenuLink>
              </li> */}
              <li>
                <Link href="/docs" passHref className="text-sm leading-tight text-gray-600 hover:underline">
                  About us
                </Link>
              </li>
              <li>
                <Link href="/blog" passHref className="text-sm leading-tight text-gray-600 hover:underline">
                  Blogs
                </Link>
              </li>
              <li>
                <Link href="/store" passHref className="text-sm leading-tight text-gray-600 hover:underline">
                  Store
                </Link>
              </li>
              <li>
                <Link href="/pages/contact" passHref className="text-sm leading-tight text-gray-600 hover:underline">
                  Contact us
                </Link>
              </li>

            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Destinations</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              {destinations.map((item) => (
                <li key={item.title}>
                  <Link href={item.href} passHref className="text-sm leading-tight text-gray-600 hover:underline">
                    {item.title}
                  </Link>
                  {item.submenu && renderSubMenu(item.submenu)}
                </li>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        {/* <NavigationMenuItem>
          <Link href="/blog" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Blogs
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem> */}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default MainMenu;
